import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeroLink } from 'components/HeroSection';

import ScrollAnchor from '../ScrollAnchor';

import { createAnnotationId, createParentAnnotationId } from './utils';

export const StyledWrapper = styled(HeroLink)`
  position: relative;
  margin-bottom: 4px;
`;

const AnnotationItem = ({ children, annotationId, annotationGroup }) => (
  <StyledWrapper href={`#${createParentAnnotationId(annotationGroup, annotationId)}`}>
    <ScrollAnchor id={createAnnotationId(annotationGroup, annotationId)} />
    {annotationId}. {children}
  </StyledWrapper>
);

AnnotationItem.propTypes = {
  children: PropTypes.node,
  annotationId: PropTypes.string,
  annotationGroup: PropTypes.string,
};

export default AnnotationItem;

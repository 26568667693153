import React from 'react';

export const annotations = [
  {
    plain: 'Autor nieznany, Kronika: Znaki uliczne, „Rzeczy Piękne” 1918, nr 2, s. 39.',
    element: (
      <React.Fragment>
        Autor nieznany, <i>Kronika: Znaki uliczne</i>, „Rzeczy Piękne” 1918, nr 2, s. 39.
      </React.Fragment>
    ),
  },
  {
    plain:
      'P. Rypson, Nie gęsi. Polskie projektowanie graficzne 1919-1949, wyd. Karakter, Kraków 2017, s. 10.',
    element: (
      <React.Fragment>
        P. Rypson, <i>Nie gęsi. Polskie projektowanie graficzne 1919-1949</i>, wyd. Karakter, Kraków
        2017, s. 10.
      </React.Fragment>
    ),
  },
  { plain: 'Ibidem, s. 60.', element: <React.Fragment>Ibidem, s. 60.</React.Fragment> },
  {
    plain:
      'M. Adler, Farby graficzne i ich znaczenie w reklamie, w: Prasa-Reklama, wyd. Polska Agencja Telegraficzna, Warszawa 1934, s. 25.',
    element: (
      <React.Fragment>
        M. Adler, <i>Farby graficzne i&nbsp;ich znaczenie w&nbsp;reklamie</i>, w:{' '}
        <i>Prasa-Reklama</i>, wyd. Polska Agencja Telegraficzna, Warszawa 1934, s. 25.
      </React.Fragment>
    ),
  },
  {
    plain: 'F. Siedlecki, Być albo nie być..., PAT 1934, s. 3.',
    element: (
      <React.Fragment>
        F. Siedlecki, <i>Być albo nie być...</i>, PAT 1934, s. 3.
      </React.Fragment>
    ),
  },
  {
    plain: 'S. Bojko, Polska sztuka plakatu, wyd. Artystyczno-Graficzne, Warszawa 1971, s. 100.',
    element: (
      <React.Fragment>
        S. Bojko, <i>Polska sztuka plakatu</i>, wyd. Artystyczno-Graficzne, Warszawa 1971, s. 100.
      </React.Fragment>
    ),
  },
  {
    plain: 'St. Z. Zakrzewski, Obserwacje, „Reklama” 1930, nr 4, s. 5.',
    element: (
      <React.Fragment>
        St. Z. Zakrzewski, <i>Obserwacje</i>, „Reklama” 1930, nr 4, s. 5.
      </React.Fragment>
    ),
  },
  {
    plain: 'Radio było jednak monopolem państwowym, czasopisma zaś komercyjne.',
    element: (
      <React.Fragment>
        Radio było jednak monopolem państwowym, czasopisma zaś komercyjne.
      </React.Fragment>
    ),
  },
  {
    plain:
      'S. Batko, Reklama w przemyśle i handlu, wyd. Miejskie Muzeum Techniczno-Przemysłowe, Kraków 1916, s. 58.',
    element: (
      <React.Fragment>
        S. Batko, <i>Reklama w&nbsp;przemyśle i&nbsp;handlu</i>, wyd. Miejskie Muzeum
        Techniczno-Przemysłowe, Kraków 1916, s. 58.
      </React.Fragment>
    ),
  },
  { plain: 'Rypson, s. 169.', element: <React.Fragment>Rypson, s. 169.</React.Fragment> },
  { plain: 'Rypson, s. 180.', element: <React.Fragment>Rypson, s. 180.</React.Fragment> },
  { plain: 'Rypson, s. 171.', element: <React.Fragment>Rypson, s. 171.</React.Fragment> },
  {
    plain:
      'Za: „szefem reklamy wielkiego domu towarowego Wanamaker w Nowym Yorku”, W. Downarowicz, Reklama prasowa, PAT 1934, s. 17.',
    element: (
      <React.Fragment>
        Za: „szefem reklamy wielkiego domu towarowego Wanamaker w&nbsp;Nowym Yorku”, W. Downarowicz,{' '}
        <i>Reklama prasowa</i>, PAT 1934, s. 17.
      </React.Fragment>
    ),
  },
  {
    plain: 'O. Langer, Reklama kupca detalisty, wyd. M. Arct, Warszawa 1937, s. 46-47.',
    element: (
      <React.Fragment>
        O. Langer, <i>Reklama kupca detalisty</i>, wyd. M. Arct, Warszawa 1937, s. 46-47.
      </React.Fragment>
    ),
  },
  { plain: 'Bojko, s. 169.', element: <React.Fragment>Bojko, s. 169.</React.Fragment> },
  {
    plain:
      'J. Mencwel, Chaos ujarzmiony, czyli reklama zewnętrzna w Polsce międzywojennej, 2013, https://publicystyka.ngo.pl/chaos-ujarzmiony-czyli-reklama-zewnetrzna-w-polsce-miedzywojennej [dostęp: 03.10.2020].',
    element: (
      <React.Fragment>
        J. Mencwel, <i>Chaos ujarzmiony, czyli reklama zewnętrzna w&nbsp;Polsce międzywojennej</i>,
        2013,
        https://publicystyka.ngo.pl/chaos-ujarzmiony-czyli-reklama-zewnetrzna-w-polsce-miedzywojennej
        [dostęp: 03.10.2020].
      </React.Fragment>
    ),
  },
  {
    plain: 'F. Siedlecki, Sztuka graficzna w reklamie, PAT 1934, s. 12.',
    element: (
      <React.Fragment>
        F. Siedlecki, <i>Sztuka graficzna w&nbsp;reklamie</i>, PAT 1934, s. 12.
      </React.Fragment>
    ),
  },
  {
    plain:
      'E. Bartłomiejczyk, O artystach grafikach reklamowych w Polsce [fr. wstępu], w: T. Lachowski, Nie tylko plakat. Polska grafika reklamowa, s. 6. Zob. też M. Ziółkowski, Opakowanie jako nowa gałąź zdobnictwa, „Rzeczy Piękne” 1927, nr 3, s. 33.',
    element: (
      <React.Fragment>
        E. Bartłomiejczyk, <i>O artystach grafikach reklamowych w&nbsp;Polsce</i> [fr. wstępu], w:
        T. Lachowski, <i>Nie tylko plakat. Polska grafika reklamowa</i>, s. 6. Zob. też M.
        Ziółkowski, <i>Opakowanie jako nowa gałąź zdobnictwa</i>, „Rzeczy Piękne” 1927, nr 3, s. 33.
      </React.Fragment>
    ),
  },
  { plain: 'Rypson, s. 62.', element: <React.Fragment>Rypson, s. 62.</React.Fragment> },
  {
    plain: 'K. Homolacs, Reklama i Sztuka (Dokończenie), „Rzeczy Piękne” 1927, nr 6, s. 82.',
    element: (
      <React.Fragment>
        K. Homolacs, <i>Reklama i&nbsp;Sztuka (Dokończenie)</i>, „Rzeczy Piękne” 1927, nr 6, s. 82.
      </React.Fragment>
    ),
  },
];

import React from 'react';
import styled from 'styled-components';

import Aleksandra from './Aleksandra';
import Tomaszewski from './Tomaszewski';
import Warda from './Warda';

export const StyledWrapper = styled.div`
  margin-top: 80px;
`;

export const TextSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black};
  margin-top: 60px;
  margin-bottom: 30px;
`;

const Texts = () => (
  <StyledWrapper>
    <Aleksandra />

    <TextSeparator />

    <Tomaszewski />

    <TextSeparator />

    <Warda />
  </StyledWrapper>
);

Texts.propTypes = {};

export default Texts;

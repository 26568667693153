import styled from 'styled-components';

const OpracowaniaWrapper = styled.div`
  h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

export default OpracowaniaWrapper;

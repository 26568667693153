import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ScrollAnchor from '../ScrollAnchor';

import { createAnnotationId, createParentAnnotationId } from './utils';

export const StyledWrapper = styled.a`
  position: relative;
`;

const Annotation = ({ title, annotationId, annotationGroup }) => (
  <StyledWrapper href={`#${createAnnotationId(annotationGroup, annotationId)}`} title={title}>
    <ScrollAnchor id={createParentAnnotationId(annotationGroup, annotationId)} />
    <sup>{annotationId}</sup>
  </StyledWrapper>
);

Annotation.propTypes = {
  annotationId: PropTypes.string,
  annotationGroup: PropTypes.string,
  title: PropTypes.node,
};

const createAnnotation = annotationGroup => props => (
  <Annotation annotationGroup={annotationGroup} {...props} />
);

export default createAnnotation;

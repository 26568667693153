import styled, { css } from 'styled-components';

import Container from 'components/Container';

export const StyledTextWrapper = styled.div`
  position: relative;
`;

export const StyledTitleWrapper = styled(Container).attrs(() => ({
  as: 'h2',
}))`
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  && {
    margin-bottom: 20px;
  }

  ${({ theme }) => css`
    ${theme.mediaQueries.extraLargeDesktop.min} {
      && {
        margin-bottom: 50px;
      }
    }
  `}
`;

export const StyledTextTitle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.Kolumbia};
`;

export const StyledTextAuthor = styled.span`
  font-size: ${({ theme }) => theme.font.size16};
  display: block;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      font-size: ${({
        theme: {
          font: { size20 },
        },
      }) => size20};
    }

    ${theme.mediaQueries.largeDesktop.min} {
      font-size: ${({
        theme: {
          font: { size24 },
        },
      }) => size24};
    }
  `}
`;

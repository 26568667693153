import styled, { css } from 'styled-components';

const ScrollAnchor = styled.span`
  position: absolute;
  left: 0;
  top: -79px;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      top: -91px;
    }
  `}
`;

export default ScrollAnchor;

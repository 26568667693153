import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'components/Seo';
import HeroSection, { PAGES } from 'components/HeroSection';
import {
  OpracowaniaWrapper,
  OpracowaniaHeroSectionContent,
  Texts,
} from 'components/PagesSpecific/Opracowania';

const opracowania = () => (
  <React.Fragment>
    <Helmet>
      <body className="lightRose" />
    </Helmet>
    <SEO title="Opracowania" />

    <OpracowaniaWrapper>
      <HeroSection page={PAGES.OPRACOWANIA} title="Opracowania">
        <OpracowaniaHeroSectionContent />
      </HeroSection>

      <Texts />
    </OpracowaniaWrapper>
  </React.Fragment>
);

export default opracowania;

import React from 'react';

import Container from 'components/Container';

import { texts } from '../constants';
import ScrollAnchor from '../ScrollAnchor';
import createAnnotation, { AnnotationItem, AnnotationItemsContainer } from '../Annotation';

import { StyledTextAuthor, StyledTextTitle, StyledTextWrapper, StyledTitleWrapper } from './styled';
import { annotations } from './wardaConstants';

const annotationGroup = 'warda';

const Annotation = createAnnotation(annotationGroup);

const Warda = () => (
  <StyledTextWrapper>
    <ScrollAnchor id={texts.warda.id} />
    <StyledTitleWrapper>
      <StyledTextTitle>{texts.warda.title}</StyledTextTitle>
      <StyledTextAuthor>{texts.warda.author}</StyledTextAuthor>
    </StyledTitleWrapper>
    <Container>
      <p>
        Dwudziestolecie międzywojenne to czas nie tylko mozolnej odbudowy polskiej państwowości, ale
        również okres samookreślenia obywateli młodego kraju w&nbsp;dziedzinach tak wydałoby się od
        siebie odległych, jak gospodarka i&nbsp;kultura. Reklama siłą rzeczy stała się wówczas
        dyscypliną wiążącą ze sobą obydwie te sfery, gdzie osiągnięcia polskich producentów oraz
        dynamikę rodzimego handlu wspierała rzesza specjalistów reprezentujących dziedziny, dzięki
        którym z&nbsp; czasem zyskała rangę gałęzi wiedzy i&nbsp;nauki. W&nbsp;wydanej na początku
        lat czterdziestych książce Stanisława Zenona Zakrzewskiego – niezwykle wpływowej postaci
        polskiej reklamy lat 30. – „Reklama. Cele, środki, rodzaje“ (Instytut Reklamy, Warszawa
        1942) czytamy: „Uczeni, artyści, inżynierowie architekci, publicyści są na jej usługach“.
      </p>
      <p>
        Początki nowoczesnej reklamy związane są z&nbsp;prasą codzienną i&nbsp;to właśnie
        w&nbsp;dziedzinie skutecznego „ogłaszania się” wypracowano jeszcze pod koniec XIX wieku
        w&nbsp;Ameryce pierwsze, w&nbsp; dużej mierze aktualne do dziś reguły mówiące
        o&nbsp;znaczeniu „nowości”, sloganu, podania powodu zakupu, czy wskazaniu na druk, jako
        głównego „wehikułu” nowoczesnej reklamy, wiele mówiły o&nbsp; zależności rodzącej się
        dziedziny od reguł pozyskiwania czytelników przez prasę. Jednak to właśnie plakat stał się
        z&nbsp;czasem jednym z&nbsp;najbardziej efektownych, choć dość drogich środków reklamy
        drukowanej. „Sprzedaż poprzez druk”, do chwili upowszechnienia reklamy kinowej i&nbsp;
        radiowej, była niewątpliwe głównym orężem w&nbsp;arsenale ówczesnej reklamy. O&nbsp;randze
        i&nbsp;dominacji owej „dźwigni handlu”, czy „motorze sprzedaży” – jak powszechnie określano
        ogłoszenie prasowe/inserat – w&nbsp;budżetach promocyjnych producentów i&nbsp;sprzedawców,
        świadczy sama definicja plakatu reklamowego, który – ponownie odwołajmy się do Zakrzewskiego
        – określano jako „(…) ogłoszenie zmniejszone co do treści, powiększone co do formatu”.
        Dzięki powielaniu w&nbsp;tysiącach egzemplarzy plakat świadczył nie tylko o&nbsp;firmie
        i&nbsp;marce, ale również popularyzował sztukę plastyczną.
      </p>
      <p>
        U&nbsp;progu XX wieku dominował na ziemiach polskich plakat poświęcony wydarzeniom
        kulturalnym, a&nbsp; do wyjątków należały, zapewne ze względu na wspomniane koszty jego
        produkcji i&nbsp;dystrybucji, profesjonalne druki reklamowe. Pomimo prób promocji
        popularnego już na zachodzie nośnika reklamy, poprzez wystawy prac najwybitniejszych
        twórców, jak choćby ta zorganizowana w&nbsp; Muzeum Przemysłowym w&nbsp;Krakowie (1898),
        plakat jako skuteczne narzędzie reklamy nie zyskał w&nbsp; Polsce jeszcze na początku XX
        wieku zbyt wielu entuzjastów. Wprowadzenie w&nbsp;1890 roku na ulice Warszawy, wzorem
        nowoczesnych miast europejskich, słupów reklamowych przez Fryderyka Koepkego spotkało się
        z&nbsp;wyraźną rezerwą w&nbsp;kręgach handlowych, a&nbsp;lokalna prasa często zarzucała nie
        tylko samym urządzeniom, ale i&nbsp;wywieszanym na nim plakatom, szpetotę psującą estetykę
        ulicy. Dominowały na nich afisze liternicze, a&nbsp;plakat artystyczny należał do rzadkości.
        W&nbsp;1927 roku, Olgierd Langer, ekonomista, prekursor nowoczesnej reklamy w&nbsp;Polsce,
        absolwent Harvard Business School i&nbsp;pierwszy wykładowca reklamy w&nbsp;Wyższej Szkole
        dla Handlu Zagranicznego we Lwowie, narzeka na wciąż niezadowalający poziom reklamy
        plakatowej i&nbsp; udziela prostych wskazówek: „(…) wzorowy afisz powinien posiadać dwie
        zalety: 1. zwracać na siebie uwagę nawet przy dużym ruchu ulicznym i&nbsp;2. musi być
        zwięzły, by jego treść czytano <i>mimochodem</i>”
        <Annotation title={annotations[0].plain} annotationId="1" />. Postulaty i&nbsp; apele, aby
        reklama – dziedzina, której rozwój wnikliwie obserwowały wówczas środowiska artystyczne –
        wykorzystała potencjał grafików, padały z&nbsp;wielu stron. Karol Homolacs, krakowski
        pedagog związany z&nbsp;środowiskiem Muzeum Przemysłowego w&nbsp;Krakowie pisał: „Trzeba,
        aby przemysłowcy i&nbsp;kupcy zrozumieli, że wyszlachetnienie reklamy, czyli postawienie jej
        na artystycznej wyżynie, leży przede wszystkim w&nbsp;interesie samej reklamy i&nbsp;że
        pieniądz wydany przez nich na estetyczne jej pogłębienie, przyniesie im zyski.
        Z&nbsp;drugiej strony plastycy, w&nbsp; tej dziedzinie powinni zrozumieć, że reklama jest
        wtedy wartościowa kiedy swoje przeznaczenie należycie spełnia. Artysta opracowując projekt
        powinien wiedzieć, że dobry obraz może być złym afiszem i&nbsp;powinien zrozumieć, że jego
        honorarjum winno się zamawiającemu sowicie opłacić. Z&nbsp;chwilą gdyby nastało obopólne
        zrozumienie wzajemnych obowiązków i&nbsp; korzyści, miasta nasze zmieniłyby obecny wygląd
        i&nbsp;zrzuciłyby z&nbsp;siebie wreszcie tę dzisiejszą szatę, która jak łachman żebraczy,
        strzępami różnych barw łatany, budzi niejednokrotnie litość i&nbsp;wstręt”
        <Annotation title={annotations[0].plain} annotationId="2" />.
      </p>
      <p>
        Pamiętając o&nbsp;trudnych początkach rodzimego plakatu w&nbsp;Polsce, należy przyjąć, że
        właśnie w&nbsp; okresie dwudziestolecia międzywojennego plakat stał się na dobre dziedziną
        docenioną, a&nbsp; wyspecjalizowani w&nbsp;jego projektowaniu graficy cieszyli się prestiżem
        cenionych twórców reklamy, których dyskretny lecz widoczny podpis podnosił wartość druku. Co
        więcej – plakat, dzięki systematycznie wzrastającej wśród zamawiających i&nbsp;odbiorców
        świadomości jego funkcji i&nbsp;celów, przekroczył tradycyjne ramy perswazji reklamowej,
        stając się ważnym medium służącym edukacji społecznej.
      </p>
      <p>
        Niewątpliwie rozwojowi nowoczesnej reklamy sprzyjał dynamiczny rozwój polskiej poligrafii,
        która wraz z&nbsp;początkiem XX wieku przechodziła okres przeobrażania produkcji na poły
        rzemieślniczej w&nbsp;przemysłową. Nowe, rewolucyjne technologie ułatwiające druk
        w&nbsp;coraz większych nakładach o&nbsp;coraz lepszej jakości, wpłynęły również na prawdziwy
        rozkwit prasy codziennej i&nbsp;periodycznej, co w&nbsp;sposób naturalny spowodowało
        zwiększenie wartości branży reklamowej, dla której „cyrkulacja” – obieg i&nbsp;dostępność
        anonsu prasowego – odgrywała znaczenie kluczowe. W&nbsp;czasach II Rzeczpospolitej dzięki
        związkom przemysłu, handlu i&nbsp; dynamicznie rozwijających się środków masowego przekazu,
        możemy mówić już o&nbsp;narodzinach masowej kultury, której nieodłącznym elementem była
        reklama. Plakat zaś od początku swojego istnienia był jej częścią, a&nbsp;widoczne
        w&nbsp;jego specyficznym rozwoju związki sztuki wyższej i&nbsp; popularnej nigdy do końca
        nie były jednoznaczne.
      </p>

      <h3>Reklama artystyczna</h3>

      <p>
        Plakat reklamowy pierwszej połowy lat dwudziestych zdradza odziedziczony po pierwszym
        dziesięcioleciu XX wieku brak odpowiednich, profesjonalnych wzorców. Rolę wiodącego ośrodka
        grafiki użytkowej i&nbsp;reklamy przejęła po Krakowie Warszawa. W&nbsp;stolicy znalazły
        swoje siedziby nie tylko najważniejsze urzędy administracji państwowej i&nbsp;gospodarczej
        kraju, ale również przedstawicielstwa wielu firm i&nbsp;banków. Tutaj również aktywne
        środowisko artystyczne skupione wokół wydziału Architektury Politechniki Warszawskiej, pod
        wpływem profesorów Zygmunta Kamińskiego i&nbsp;Edmunda Bartłomiejczyka, już wkrótce
        zadecyduje o&nbsp;nowoczesnym obliczu polskiego plakatu i&nbsp;grafiki reklamowej. Obydwaj
        pedagodzy hołdowali tradycyjnemu warsztatowi rysunkowemu, a&nbsp;ich projekty plakatowe
        odznaczały się pewną dekoracyjnością i&nbsp;realizmem, połączonym z&nbsp;nienagannym,
        tradycyjnym liternictwem. Elegancja ich druków na równi z&nbsp; wymagającym od studentów
        wszechstronności programem zajęć, wywarła wpływ na grafikę użytkową licznych adeptów
        wydziału. Z&nbsp;drugiej strony pojawiające się nowatorskie tendencje w&nbsp; architekturze,
        takie jak funkcjonalizm i&nbsp;konstruktywizm, w&nbsp;przekonaniu ówczesnych twórców
        najpełniej realizowały wyzwania nowoczesnego społeczeństwa i&nbsp;ugruntowały wśród młodych
        architektów przekonanie o&nbsp;własnym potencjale, który mogą realizować na różnych polach.
      </p>
      <p>
        Grafika użytkowa stała się dla absolwentów architektury wyzwaniem i&nbsp;naturalną
        konsekwencją rozbudzonych przez profesorów zainteresowań współczesną plastyką. Tworzenie
        plakatów, grafika reklamowa w&nbsp;wielu postaciach, były przecież tą stroną życia
        codziennego, która wymagała zaprojektowania zgodnie ze swoim przeznaczeniem, podobnie jak
        budynek, czy aranżacja wnętrza mieszkalnego. Idee Bauhausu – nowatorskiej szkoły
        architektonicznej założonej przez Waltera Gropiusa w&nbsp;Weimerze, następnie przeniesionej
        do Dessau w&nbsp;Niemczech – przenikały za pośrednictwem licznych, bezpośrednich kontaktów,
        oraz pism artystycznych i&nbsp; publikacji również do Polski, choć nie znalazły miejsca
        w&nbsp;programach uczelni artystycznych. Pomimo tego rodzące się na zachodzie koncepcje
        nowej architektury, podziałały ożywczo na wszelkie pola projektowania
        i&nbsp;zrewolucjonizowały również projektowanie graficzne. Drugą, bardziej prozaiczną
        przyczyną aktywności młodych architektów na rynku reklamowym była możliwość zarobkowania.
        Udział w&nbsp;konkursach na plakat, przyjmowanie zleceń na drobne druki, ilustracje
        i&nbsp;reklamy prasowe, czy aranżowanie witryn sklepowych, były zajęciami pewniejszymi od
        możliwości realizacji własnego projektu architektonicznego.
      </p>
      <p>
        Jednym z&nbsp;pierwszych i&nbsp;najwybitniejszym spośród architektów – grafików reklamowych,
        był Tadeusz Gronowski. W&nbsp;ramach Atelier reklamy artystycznej „Plakat”, które założył
        wspólnie z&nbsp;Jerzym Gelbardem i&nbsp;Antonim Bormanem – późniejszym właścicielem agencji
        reklamowej „Ara” i&nbsp; wydawcą „Wiadomości Literackich” – zaprojektował plakaty
        reklamujące gumy powozowe firmy Pneumatyk z&nbsp;Poznania i&nbsp;grzejniki marki Stąporków
        S.A. Starannie opracowane liternictwo, kolorystyka i&nbsp;sugestywny, pełen elegancji
        rysunek, były świadectwem nie tylko inspiracji, jakie czerpał wprost z&nbsp;Paryża, ale
        przede wszystkim dojrzałego podejścia do projektowania graficznego. Jednak największe
        sukcesy Gronowskiego miały dopiero nadejść: Grand Prix na Wystawie Sztuk Dekoracyjnych
        i&nbsp;Przemysłu Współczesnego w&nbsp;Paryżu w&nbsp;1925 roku za plakat{' '}
        <i>IV Międzynarodowe Targi Wschodnie</i> (1924) oraz – powszechnie uznawany za wzorcowy
        przykład nowoczesnego plakatu reklamowego lat 20. – <i>Radion sam pierze</i> (1926). Kolejny
        sukces Gronowskiego na Wystawie Plakatu Reklamowego w&nbsp;Monachium, towarzyszącej
        Światowemu Kongresowi Reklamy w&nbsp;Berlinie w&nbsp;1929 roku, potwierdził międzynarodową
        renomę jego twórczości i&nbsp;niepodważalną pozycję w&nbsp;kraju.{' '}
        <i>Gentelman. Śniegowiec wytwornej damy</i> uznany został przez recenzentów za najlepszy
        spośród wszystkich zaprezentowanych na wystawie plakatów. Gronowski, zafascynowany kulturą
        francuską, przez pewien czas działał również jako grafik w&nbsp; Paryżu, gdzie utrzymywał
        swoją pracownię, wniósł do polskiej grafiki reklamowej ujmującą elegancję i&nbsp;swobodę,
        znakomite wyczucie formy, koloru i&nbsp;rysunku oraz to, co w&nbsp;reklamie najważniejsze –
        umiejętność znalezienia odpowiedniej anegdoty i&nbsp;symbolu. Tadeusz Gronowski dzięki
        swojej pracowitości i&nbsp;talentowi – z&nbsp;pokorą zaznaczał aspekt dobrego „rzemiosła”
        w&nbsp; swojej twórczości – stał się najbardziej wpływową postacią polskiej grafiki
        reklamowej dwudziestolecia międzywojennego. Realizował projekty znaków firmowych (Polskie
        Linje Lotnicze LOT), winiet (Wiadomości Literackie), opakowań (Wedel), reklam prasowych,
        ulotek, okładek książkowych. Był aktywny na niemal każdym polu grafiki reklamowej, choć
        największe sukcesy przyniósł mu właśnie plakat. Jako jedyny członek grafik-projektant
        stowarzyszenia „Rytm”, miał swój udział w&nbsp;dążeniach w&nbsp;wypracowaniu polskiego
        stylu, a&nbsp;równoczesna fascynacja paryskim art déco, pomogła mu przenieść na polski grunt
        osiągnięcia takich mistrzów francuskiej grafiki reklamowej, jak Adolphe Mauron zwany
        Cassandre.
      </p>
      <p>
        Równie oryginalną i&nbsp;wpływową, choć zupełnie odmienną osobowością polskiego plakatu
        dwudziestolecia był Stefan Norblin, potomek arystokratycznego rodu o&nbsp;tradycjach
        artystycznych, właścicieli znanego Zakładu Platerniczego Norblinów. Malarz, wzięty
        portrecista warszawskich elit towarzyskich, rysownik i&nbsp;ilustrator,
        w&nbsp;przeciwieństwie do Gronowskiego zdobywał wykształcenie artystyczne w&nbsp;trakcie
        licznych podróży po Europie. Okres największej aktywności projektowej Norblina przypada na
        drugą połowę lat 20., kiedy na zlecenie Ministerstwa Komunikacji, a&nbsp;następnie Polskich
        Kolei Państwowych realizuje cykl plakatów turystycznych reklamujących Polskę, m.in.{' '}
        <i>Polska. Kraj polowań, Lwów, Wilno</i>. Mamy tu do czynienia z&nbsp;plakatem kontynuującym
        tradycje „malarskiego modernizmu”, nurtu nadal w&nbsp;tym czasie popularnego
        w&nbsp;Niemczech i&nbsp;w nieco bardziej graficznej formule w&nbsp;Wielkiej Brytanii. Atutem
        kompozycji Norblina była pełna gestu kontrastowa, bogata kolorystyka i&nbsp; realistyczna
        forma. Ta znacznie bardziej tradycyjna od realizacji Gronowskiego i&nbsp;młodych architektów
        formuła grafiki reklamowej przyniosła Norblinowi licznych zwolenników. Temat w&nbsp;
        plakacie Norblin rzadko prezentował za pomocą anegdoty lub symbolu, częściej ograniczał się
        do wyrazistej, przykuwającej barwą i&nbsp;rysunkiem ilustracji, opatrzonej czytelnym
        liternictwem. Wśród stałych klientów artysty, obok instytucji państwowych, były również
        wydawnictwa – dla Gebethnera i&nbsp;Wolffa stworzył rozpoznawalny cykl okładek popularnej
        w&nbsp;latach 30. Biblioteki Groszowej.
      </p>
      <p>
        Obok tak rozpoznawalnych twórców jak Gronowski i&nbsp;Norblin w&nbsp;drugiej połowie lat 20.
        pojawiają się graficy – absolwenci wspomnianej Politechniki Warszawskiej i&nbsp;powołanego
        w&nbsp;1926 roku, pierwszego wydziału grafiki użytkowej Akademii Sztuk Pięknych
        w&nbsp;Warszawie. Plakatem reklamowym zajmowali się intensywnie absolwenci obydwu
        warszawskich uczelni, często, zwłaszcza w&nbsp;połowie lat 30. współpracujący w&nbsp;ramach
        dwu- i&nbsp;trzyosobowych spółek autorskich lub studiów graficznych. Do najsłynniejszych
        z&nbsp;nich należała działająca w&nbsp;latach 1933-36 „Mewa” (Edward Manteuffel, Antoni
        Wajwód, Jadwiga Hładki), Atelier Girs-Barcz (Antoni Girs, Jerzy Barcz we współpracy
        z&nbsp;Bolesławem Surałło i&nbsp;Tadeuszem Kryszakiem). Znane są realizacje Marka
        Żuławskiego i&nbsp;Tadeusza Trepkowskiego, Eryka Lipińskiego i&nbsp;Artura Rubinrota
        i&nbsp;wielu innych. Architekci często zawiązywali spółki realizując pojedyncze projekty,
        jak Jerzy Hryniewiecki i&nbsp;Stefan Osiecki, Maciej Nowicki i&nbsp;Stanisława Sandecka.
        Jedną z&nbsp; oryginalniejszych spółek tego czasu, było założone przez grafików
        niezwiązanych z&nbsp; warszawskimi uczelniami studio Levitt-Him (Jerzy Him, Jan Levitt).
        Metoda pracy zespołowej znakomicie sprawdzała się przy szybkiej i&nbsp;profesjonalnej
        realizacji zleceń i&nbsp;zwiększała niekiedy szanse na sukces w&nbsp;organizowanych coraz
        liczniej konkursach na plakat.
      </p>

      <h3>Kryzys i&nbsp;progres</h3>

      <p>
        Paradoksalnie okres nachodzącego kryzysu gospodarczego lat 1929-30 stał się najbardziej
        owocnym okresem w&nbsp;polskiej reklamie i&nbsp;grafice użytkowej. W&nbsp;roku 1929 wychodzi
        pierwszy numer organu Polskiego Związku Reklamowego (PZR), którego „(…) jedynym celem ma być
        podniesienie poziomu reklamy pod względem etycznym i&nbsp;poprawa jej pod względem
        artystycznym i&nbsp;handlowym”
        <Annotation title={annotations[0].plain} annotationId="3" />. Odtąd na łamach „Reklamy” do
        końca lat 30. pojawiać się będą specjalistyczne artykuły, a&nbsp;pismo stanie się ważnym
        forum wymiany opinii i&nbsp;narzędziem promocji również polskiej grafiki użytkowej
        i&nbsp;plakatu. Działania związku od początku związane są z&nbsp;próbą podniesienia poziomu
        artystycznego reklamy i&nbsp;plakatu nie tylko teoretycznie, ale i&nbsp;praktycznie.
        W&nbsp;1929 roku Zakrzewski z&nbsp;ramienia PAT opracowuje plan kampanii propagandowej
        i&nbsp;reklamowej Powszechnej Wystawy Krajowej w&nbsp;Poznaniu – największej
        w&nbsp;dwudziestoleciu międzywojennym propagandy polskiego przemysłu i&nbsp;handlu. Powstają
        wówczas plakaty projektu m.in. Zygmunta Kamińskiego, Tadeusza Gronowskiego i&nbsp; Stefana
        Norblina. Na samej wystawie korzystnie prezentuje się polski przemysł graficzny
        reprezentowany przez papiernie, drukarnie oraz wydawców. Dwa lata później, w&nbsp;1931 roku,
        związek patronuje pierwszej wystawie grafiki użytkowej i&nbsp;reklamowej, zorganizowanej
        przez Związek Słuchaczy Architektury Politechniki Warszawskiej w&nbsp;podziemiach Domu
        Towarowego Braci Jabłkowskich (przedstawiciel firmy – Feliks Jabłkowski był jednym
        z&nbsp;pierwszych członków Związku).
      </p>
      <p>
        W&nbsp;1933 roku z&nbsp;inspiracji Zakrzewskiego i&nbsp;przy prawnym wsparciu PZR, powstaje
        pierwsza zawodowa organizacja skupiająca grafików użytkowych w&nbsp;Polsce: Koło Artystów
        Grafików Reklamowych. Inicjatorem i&nbsp;pierwszym prezesem KAGR był prof. Edmund
        Bartłomiejczyk, a&nbsp;w skład zarządu weszli Tadeusz Gronowski, Jan Mucharski i&nbsp;Edmund
        John. Do końca lat 30. organizacja skupiła w&nbsp;swoich szeregach najwybitniejszych
        projektantów, a&nbsp;swoją działalnością wystawienniczą, m.in. w&nbsp;salach Instytutu
        Propagandy Sztuki w&nbsp;Warszawie, skutecznie popularyzowała nowoczesną grafikę użytkową.
        Przy wsparciu PZR organizacja miała coraz większy wpływ na propagandę i&nbsp;reklamę
        przedsiębiorstw oraz monopoli państwowych: Koło stało się ich ważnym partnerem przy
        organizacji konkursów projektowych. Członkowie KAGR-u często podpisywali swoje prace
        nazwiskiem i&nbsp;skrótem organizacji, zaznaczając w&nbsp;ten sposób swoją zawodową
        przynależność. W&nbsp;gronie członków Koła znaleźli się projektanci z&nbsp;różnych
        środowisk, których połączył prof. Bartłomiejczyk – pedagog Politechniki Warszawskiej,
        a&nbsp;od 1926 roku również kierownik pierwszej katedry grafiki użytkowej na Akademii Sztuk
        Pięknych w&nbsp; Warszawie.
      </p>
      <p>
        Plakat reklamowy dwudziestolecia, jako dziedzina grafiki użytkowej, w&nbsp;której doskonale
        odbijały się aspiracje, potencjał i&nbsp;dynamika przemian kulturowych ówczesnej Polski,
        jest znakomitym dokumentem epoki. Kultura graficzna II Rzeczpospolitej imponuje dynamiką
        i&nbsp; otwartością, w&nbsp;której odnaleźć możemy źródła własnej, kulturowej tożsamości.
      </p>
      <p>
        Skrócony i&nbsp;opracowany tekst pochodzi z&nbsp;książki{' '}
        <i>Dwudziestolecie międzywojenne. Tom 49. Plakat i&nbsp;reklama</i>, wydanej przez Edipresse
        Polska w&nbsp;2015 r.
      </p>
    </Container>

    <AnnotationItemsContainer>
      {annotations.map(({ element }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AnnotationItem key={index} annotationGroup={annotationGroup} annotationId={`${index + 1}`}>
          {element}
        </AnnotationItem>
      ))}
    </AnnotationItemsContainer>
  </StyledTextWrapper>
);

Warda.propTypes = {};

export default Warda;

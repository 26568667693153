import React from 'react';

import Container from 'components/Container';

import { texts } from '../constants';
import createAnnotation, { AnnotationItem, AnnotationItemsContainer } from '../Annotation';
import ScrollAnchor from '../ScrollAnchor';

import { StyledTextAuthor, StyledTextTitle, StyledTextWrapper, StyledTitleWrapper } from './styled';
import { annotations } from './aleksandraConstants';

const annotationGroup = 'aleksandra';

const Annotation = createAnnotation(annotationGroup);

const Aleksandra = () => (
  <StyledTextWrapper>
    <ScrollAnchor id={texts.aleksandra.id} />
    <StyledTitleWrapper>
      <StyledTextTitle>{texts.aleksandra.title}</StyledTextTitle>
      <StyledTextAuthor>{texts.aleksandra.author}</StyledTextAuthor>
    </StyledTitleWrapper>
    <Container>
      <p>
        „Spokojne niegdyś ulice zdobne w&nbsp;piękne portale, wspaniałe kute balkony, kraty
        i&nbsp;wywieszki, napełniono wrzaskiem reklamy. Wdarła się ona wszędzie. Zasłoniła
        płaskorzeźby, figury, pilastry i&nbsp;kolumny. W&nbsp;wiecznej kłótni z&nbsp;niemi błyszczą
        zasmarowane zjadliwemi barwami papierowe płachty plakatów”
        <Annotation title={annotations[1 - 1].plain} annotationId="1" />.
      </p>
      <p>
        Powyższy fragment wypowiedzi obrońcy wielkomiejskich pejzaży ukazał się w&nbsp;1918 roku
        w&nbsp; „Rzeczach Pięknych”, miesięczniku poświęconym sztuce. I&nbsp;warto przy tym
        wspomnieć, że pierwsze lata po odzyskaniu przez Polskę niepodległości nie były dla reklamy
        łaskawe. Musieliśmy podnieść się po wojnie, po latach rozbiorów; brakowało towarów do
        zareklamowania, pieniędzy do wydania i&nbsp;nowoczesnego sprzętu w&nbsp;drukarniach, wobec
        czego reklama przyjmowała często starsze, przedwojenne formy
        <Annotation title={annotations[2 - 1].plain} annotationId="2" />. Jej sytuacja zaczęła się
        zmieniać w&nbsp;drugiej połowie lat 20. (głównie dzięki stabilizacji polskiej waluty
        w&nbsp;latach 1924-1925
        <Annotation title={annotations[3 - 1].plain} annotationId="3" />
        ). Zakładano studia projektowe kształtujące nowoczesne oblicze branży reklamowej, pisano
        podręczniki i&nbsp;instrukcje – nie tylko o&nbsp;projektowaniu, ale i&nbsp;psychologicznych
        mechanizmach kupna oraz sprzedaży, wykorzystując do tego również osiągnięcia psychoanalizy.
        Przykładowo w&nbsp;katalogu Prasa – Reklama Polskiej Agencji Telegraficznej (PAT)
        z&nbsp;1934 roku, czytamy, że kombinacja pomarańczowego i&nbsp;żółtego okaże się
        najodpowiedniejsza dla artykułów budowlanych, dla perfum zaś odcienie żółtego
        z&nbsp;karminowym
        <Annotation title={annotations[4 - 1].plain} annotationId="4" />. Zaczęto doceniać siłę
        i&nbsp; możliwości (dobrej) reklamy.
      </p>
      <p>
        Ta umościła się na dobre dopiero w&nbsp;latach trzydziestych. A&nbsp;jedną z&nbsp;przyczyn
        był, paradoksalnie, kryzys w&nbsp;1929 roku. Polska zaczęła borykać się ze wzrostem
        bezrobocia, nadprodukcją i&nbsp;ograniczoną konsumpcją
        <Annotation title={annotations[5 - 1].plain} annotationId="5" />. Gdzie rynek zaczyna
        upadać, tam trwa wojna o&nbsp;każdego klienta. Gdzie trwa wojna o&nbsp;klienta, tam
        potrzebna jest reklama. Wojnę reklamową zaczęli więc, prędzej czy później, wszyscy –
        niezależnie od tego, czy był to biały cukier (krzepiący albo i&nbsp;nie), czy luksusowe
        meble
        <Annotation title={annotations[6 - 1].plain} annotationId="6" />.
      </p>
      <p>
        Reklama, reklama, reklama. Kapeluszy, butów, prezerwatyw Ultra, soli do kąpieli, pasty do
        zębów Chlorodont, maści na piegi, maści na wrzody i&nbsp;krosty Propidex, mydła Biały Jeleń,
        żyletek, polskiego Fiata, linii lotniczych „LOT”, browaru, wódki wyborowej, zwijek
        papierosowych, kawy, czekolady Wedla, morfiny, mebli Thoneta, loterii pieniężnych, Pocztowej
        Kasy Oszczędności… Nie ulega wątpliwości, że „wydawanie pieniędzy należy do rzędu boskich
        rozkoszy”, nawet w&nbsp;kryzysach
        <Annotation title={annotations[7 - 1].plain} annotationId="7" />. W&nbsp;okresie
        dwudziestolecia międzywojennego reklama drukowana była tą, która zdominowała rynek.
        I&nbsp;nie prześcignęło jej żadne inne medium, chociaż zarówno kino, jak i&nbsp;radio
        zaczęły mieć już znaczny wpływ, z&nbsp;kamienic bzyczały neony, a&nbsp;witryny sklepowe
        prześcigały się w&nbsp;wytworności
        <Annotation title={annotations[8 - 1].plain} annotationId="8" />. W&nbsp;drukowanych formach
        reklamy królowała zaś prasa. Postępujący rozwój czytelnictwa w&nbsp;Polsce (tuż po wojnie
        analfabetyzm był powszednim problemem), a&nbsp;wraz z&nbsp;nim wydawnictw periodycznych,
        przyczynił się do rozkwitu ogłoszeń w&nbsp;gazetach codziennych, tygodnikach,
        miesięcznikach, pismach fachowych. Tych było zresztą tyle, że ludzie mieli w&nbsp;czym
        przebierać.
      </p>
      <p>
        Skąd firmy i&nbsp;przedsiębiorstwa wiedziały w&nbsp;takim razie, gdzie umieszczać reklamy,
        skoro opcji było tak wiele? To zależało przede wszystkim od charakteru produktu czy marki.
        Już w&nbsp;1919 roku możemy przeczytać, że „przy posługiwaniu się prasą codzienną wiele
        znaczącym momentem jest, w&nbsp;jakich sferach dziennik ma głównie odbiorców i&nbsp;jaki
        jest jego nakład. Artykułu luksusowego nikt nie będzie ogłaszał w&nbsp;dziennikach
        czytywanych głównie przez sferę robotniczą, a&nbsp;tanich przedmiotów w&nbsp;pismach dla
        arystokracyi. To rozumie się samo przez się”
        <Annotation title={annotations[9 - 1].plain} annotationId="9" />.
      </p>
      <p>
        Prasa codzienna była najodpowiedniejszym miejscem dla reklamy kampanii społecznych czy
        produktów codziennego użytku, z&nbsp;których korzysta każdy obywatel, niezależnie od jego
        stanu majątkowego. W&nbsp;miesięcznikach, magazynach ilustrowanych czy fachowych pismach,
        reklamy towarów bardziej luksusowych były zamieszczane odpowiednio do swojego charakteru
        <Annotation title={annotations[10 - 1].plain} annotationId="10" />. Nikt przecież nie
        umieściłby reklamy perfum w&nbsp;„Przeglądzie Mechanicznym”, tylko w&nbsp;„Kobiecie
        w&nbsp;Świecie i&nbsp;w Domu” – okładki dla tego czasopisma projektowali zresztą graficy
        najbardziej znani i&nbsp;rozchwytywani, jak np. Tadeusz Gronowski, Stefan Norblin czy Jan
        Mucharski
        <Annotation title={annotations[11 - 1].plain} annotationId="11" />. Magazyny nie były też
        kolorowe w&nbsp;całości. Zazwyczaj to okładka z&nbsp;paroma stronami i&nbsp;reklamową
        rozkładówką cieszyły oko odskocznią od czarnego tuszu.
      </p>
      <p>
        Jeśli ktoś chciał zlecić wykonanie reklamy, musiał zgłosić się do agencji reklamowej. Lata
        trzydzieste to pełen ich rozkwit, szczególnie w&nbsp;dużych ośrodkach miejskich, jak
        Warszawa, Kraków, Katowice, Lwów, Poznań. Z&nbsp;państwowych największą popularnością
        cieszyły się Atelier Grafiki Polskiej Agencji Telegraficznej (PAT) oraz Polska Poczta,
        Telegraf i&nbsp;Telefon (PPTT). Zarówno w&nbsp;tych, jak i&nbsp;prywatnych atelier pracowały
        rzesze graficzek i&nbsp;grafików po Akademii Sztuk Pięknych czy absolwentek
        i&nbsp;absolwentów Politechniki z&nbsp;Wydziału Architektury, głównie ośrodka warszawskiego.
        U&nbsp;tych ostatnich ciężko było o&nbsp;kierunkowe zlecenie, a&nbsp;na reklamę czy
        w&nbsp;ogóle grafikę użytkową było niekończące się zapotrzebowanie. Projektantki oraz
        projektanci sami często łączyli się w&nbsp;spółki: Atelier Graficzne „Ruch” (m.in. Tadeusz
        Kryszak i&nbsp;Jan Mucharski), Atelier „Plakat” (m.in. Tadeusz Gronowski, Jan Mucharski),
        Atelier „Mewa” (Jadwiga Hładki, Edward Manteuffel, Antoni Wajwód), Atelier GirsBarcz;
        tandemy: LevittHim, Nowicki-Sendecka, Osiecki-Skolimowski. Powstawały również stowarzyszenia
        zawodowe – Polski Związek Reklamowy (1928) oraz prestiżowe Koło Artystów Grafików
        Reklamowych (KAGR, 1933), wydające czasopismo branżowe „Reklama”
        <Annotation title={annotations[12 - 1].plain} annotationId="12" />.
      </p>
      <p>
        Czym charakteryzowała się reklama w&nbsp;prasie? Musiała być przede wszystkim treściwa, ale
        zwięzła, z&nbsp;przykuwającym oko hasłem, porządnym układem graficznym i&nbsp;najlepiej
        z&nbsp;ilustracją. Całą instrukcję podaje przytoczony już wcześniej katalog PATu (1934):
        „głęboka znajomość towaru, słowa krótkie, zdania zwięzłe, nowe idee, jedna myśl
        w&nbsp;zdaniu, zrozumiałe dla dziecka, ścisłość wyrażeń, logika, prawda, unikanie przesady,
        entuzjazm, apel”
        <Annotation title={annotations[13 - 1].plain} annotationId="13" />. PAT ostrzegał przed
        przesadną ilością tekstu w&nbsp;reklamie. Ale, jak zdarzało się dość przekornie, im
        ogłoszenie drobniejsze, tym większa skłonność do zapełniania powierzchni anonsów (dawniej
        inseratów), jak tylko się dało.
      </p>
      <p>
        Nie samą jednak prasą ludzie żyli. Dzięki rozwojowi poligrafii możliwe były druki
        wielkoformatowe, takie jak afisz i&nbsp;plakat. Afisz, rodzic plakatu, to przewaga treści,
        niezależnie od jego charakteru – reklamowego, propagandowego czy informacyjnego. Dlatego
        jego forma, podporządkowana tekstowi, nie manifestowała walorów artystycznych. Inaczej było,
        i&nbsp;jest, z&nbsp;plakatami. Te również miały przekazywać informację, ale skondensowaną
        i&nbsp;połączoną z&nbsp;obrazem, najczęściej w&nbsp;taki sposób, by całość budziła
        skojarzenia, poruszała się w&nbsp;bliskiej widzom ikonosferze, przykuwała ich wzrok na
        ulicy. Sposób przekazania informacji miał być oparty na suspensie (elemencie zaskoczenia),
        by przechodzień mógł nie tylko plakat zauważyć, ale jeszcze przyswoić i&nbsp;zapamiętać jego
        treść. Dzięki syntetyczności informacji i&nbsp;grafiki można go jednocześnie czytać
        i&nbsp;oglądać; to trochę plakatowy paradoks, gdy jednocześnie „czytamy” obraz
        i&nbsp;„oglądamy” tekst.
      </p>
      <p>
        Zarówno wtedy, jak i&nbsp;dzisiaj, najpowszechniejszym formatem plakatu był ten zbliżony do
        70&nbsp;x&nbsp;100 cm, ale powstawały również plakaty w&nbsp;większej skali, przysłaniające
        niekiedy lico kamienicy. W&nbsp;interesującym nas okresie był on kosztowną formą reklamy.
        Wymagający wynajęcia grafika do projektu i&nbsp;drukowany w&nbsp;kolorze – bywał drogi
        w&nbsp;stosunku do krótkiego czasu ekspozycji
        <Annotation title={annotations[14 - 1].plain} annotationId="14" />.
      </p>
      <p>
        A&nbsp;za ekspozycję też trzeba było zapłacić. Były do tego wyznaczone specjalne miejsca
        (słupy ogłoszeniowe, gabloty na dworcach, w&nbsp;tramwajach…), choć nie znaczy to, że
        wszyscy się do tego dostosowywali. Notorycznie łamano prawo przez wywieszanie plakatów na
        nie swoich płotach czy terenach budowy
        <Annotation title={annotations[15 - 1].plain} annotationId="15" />. Podatek od reklam
        w&nbsp; przestrzeni miejskiej został wprowadzony w&nbsp;1923 roku i&nbsp;dotyczył również
        innych jej form
        <Annotation title={annotations[16 - 1].plain} annotationId="16" />.
      </p>
      <p>
        Reklam w&nbsp;postaci innych druków ulotnych było mnóstwo. Foldery i&nbsp;katalogi,
        prospekty (inaczej ulotki czy też broszurki zawierające informacje o&nbsp;danej firmie oraz
        reklamujące je), cenniki, blankiety (dzisiaj paragony; wcześniej były wydrukami
        z&nbsp;firmowymi nagłówkami, przeznaczonymi do wypełnienia), druki firmowe, nalepki na
        szyby, etykiety, papiery do zawijania, pocztówki, znaczki pocztowe. Poza prasą
        i&nbsp;reklamą outdoorową istniała dla reklamy jeszcze droga pocztowa – razem z&nbsp;listami
        doręczano różnego rodzaju druki. Jednak „nie dość wydrukować w&nbsp;nich nazwę towaru
        i&nbsp;adres firmy na szarym marnym papierze; taki świstek doręczony wraz z&nbsp;listami
        irytuje publiczność, zaśmieca jej mieszkanie, zamiast się nim interesować rzuca go jak
        najprędzej bez czytania do kosza. Inaczej rzecz się przedstawia, jeśli taka ulotka, zarówno
        w&nbsp;kolorze jak rysunku, pięknie jest skomponowana”
        <Annotation title={annotations[17 - 1].plain} annotationId="17" />.
      </p>
      <p>
        Warto też powiedzieć słowo o&nbsp;opakowaniach. Te idące na eksport były przecież reklamą
        nie tylko towarów, ale i&nbsp;całej kondycji grafiki użytkowej w&nbsp;Polsce
        <Annotation title={annotations[18 - 1].plain} annotationId="18" />. Opakowanie było
        o&nbsp;tyle ważne, że mogło zachęcić przecież do kupna (albo i&nbsp;nie), co jest zresztą
        i&nbsp;dzisiaj jak najbardziej aktualne. A&nbsp;skoro jesteśmy już przy „opakowaniach” –
        inną, zaskakującą formą reklamy, były okładki czy obwoluty książek. Zaskakującą, bo przecież
        zaliczamy je dzisiaj do grafiki wydawniczej, nie reklamowej. Ale to właśnie okładki miały
        być tym, co zachęci czytelnika do zakupu danej książki z&nbsp;konkretnego wydawnictwa
        (przykładowo serii Biblioteki Groszowej czy Biblioteki Domu Polskiego). Drukowane
        w&nbsp;środku na kiepskim papierze, to właśnie okładką walczyły o&nbsp;czytelników – dlatego
        te zamawiane były u&nbsp;grafików młodych, więc jeszcze nie za drogich, ale zyskujących już
        pierwsze publiczne uznanie
        <Annotation title={annotations[19 - 1].plain} annotationId="19" />. Lata 30. to również boom
        na fotomontaże – jednymi z&nbsp;gigantów i&nbsp;gigantek w&nbsp;tej dziedzinie byli
        Mieczysław Szczuka, Teresa Żarnowerówna czy Mieczysław Berman.
      </p>
      <p>
        O&nbsp;kondycji i&nbsp;międzynarodowym charakterze polskiej reklamy w&nbsp;latach 30.
        świadczył m.in. sukces naszych grafików na Światowej Wystawie w&nbsp;Paryżu, która odbyła
        się w&nbsp;1937 roku. Niewątpliwie siła (dobrej) reklamy była doceniana w&nbsp;okresie
        międzywojnia. Krytykowano wszelkie nieudolności w&nbsp;jej wykonywaniu, pisano poradniki,
        jak uniknąć „barbaryzacji” miasta przez zalew różnorakich form reklamowych, słowem – starano
        się, by wszystko było dopieszczone od hasła, przez treść, po ilustracje. Rezultaty bywały
        też różne, co nie zmieniło się do dzisiaj. Ale ówczesna grafika reklamowa, z&nbsp;mocną
        kadrą grafików i&nbsp;profesjonalną działalnością branżową, nie była dziedziną marginalną
        czy lekceważoną, choć może dzisiaj w&nbsp;naszych oczach niekiedy kuriozalną
        i&nbsp;memiczną. Była kolejną ważną gałęzią polskiego projektowania dwudziestolecia
        i&nbsp;kamieniem milowym dla dzisiejszego świata reklamy, chociaż wciąż często pomijanym.
        „Bo nareszcie nie wstydzimy się powiedzieć, że reklama jest sztuką i&nbsp;to sztuką zupełnie
        poważną” (1927)
        <Annotation title={annotations[20 - 1].plain} annotationId="20" />.
      </p>
    </Container>

    <AnnotationItemsContainer>
      {annotations.map(({ element }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AnnotationItem key={index} annotationGroup={annotationGroup} annotationId={`${index + 1}`}>
          {element}
        </AnnotationItem>
      ))}
    </AnnotationItemsContainer>
  </StyledTextWrapper>
);

Aleksandra.propTypes = {};

export default Aleksandra;

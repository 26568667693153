import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { TextAndImageContainer } from 'components/Container';

import TextFigure from '../components/TextFigure';
import { texts } from '../constants';
import ScrollAnchor from '../ScrollAnchor';

import { StyledTextAuthor, StyledTextTitle, StyledTextWrapper, StyledTitleWrapper } from './styled';

export const StyledImage = styled(GatsbyImage)`
  margin-top: 20px;
  margin-bottom: 24px;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin-top: 0;
      order: 1;
      margin-bottom: auto;
    }
  `}

  ${({ $visibleOnDesktop, theme }) =>
    $visibleOnDesktop &&
    css`
      display: none;

      ${theme.mediaQueries.smallDesktop.min} {
        display: block;
      }
    `}

  ${({ $visibleOnMobile, theme }) =>
    $visibleOnMobile &&
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: none;
      }
    `}
`;

const Tomaszewski = () => {
  const { allImageSharp } = useStaticQuery(graphql`
    query TomaszewskiImages {
      allImageSharp(filter: { fixed: { originalName: { regex: "/opracowania-.+/" } } }) {
        edges {
          node {
            fluid(sizes: "(max-width: 991px) 991px, (max-width: 1199px) 350px, 400px") {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `);

  const images = allImageSharp.edges.reduce(
    (
      acc,
      {
        node: {
          fluid: { originalName, ...fluid },
        },
      },
    ) => {
      const key = originalName.substring(0, originalName.indexOf('.'));

      acc[key] = fluid;

      return acc;
    },
    {},
  );

  return (
    <StyledTextWrapper>
      <ScrollAnchor id={texts.tomaszewski.id} />
      <StyledTitleWrapper>
        <StyledTextTitle>{texts.tomaszewski.title}</StyledTextTitle>
        <StyledTextAuthor>{texts.tomaszewski.author}</StyledTextAuthor>
      </StyledTitleWrapper>
      <TextAndImageContainer>
        <TextFigure caption="Il. 1" $visibleOnDesktop fluid={images['opracowania-1']} />

        <div>
          <p>
            Po pierwszej wojnie światowej, po ponad stu dwudziestu latach niewoli,
            w&nbsp;listopadzie 1918 roku Polska odzyskała niepodległy byt. W&nbsp;polskich
            drukarniach zwiastunem tego, że idzie nowe, towarzyszył pierwszy patriotyczny odruch
            zaklejania niemieckojęzycznych i&nbsp; rosyjskich napisów w&nbsp;katalogach czcionek,
            w&nbsp;„kongresówce” wzorów pism drukarskich składanych cyrylicką grażdanką.
          </p>
          <TextFigure caption="Il. 1" $visibleOnMobile fluid={images['opracowania-1']} />
          <p>
            W&nbsp;rozmaitych opracowaniach historycznych, w&nbsp;literaturze wspomnieniowej
            i&nbsp;pamiętnikach z&nbsp; byłych trzech zaborów pisano, że „wybuchła Polska”.
            Metaforyczne określenie łączono często z&nbsp;entuzjazmem społecznym, pośpiesznym
            organizowaniem niepodległej państwowości, gwałtownym rywalizowaniem różnych koncepcji
            politycznych. Niebawem językowa przenośnia zaczęła pasować do sytuacji jaka zaistniała
            w&nbsp;środowiskach artystycznych. Współbrzmiała z&nbsp;wybuchem istniejących
            jednocześnie i&nbsp;konkurujących ze sobą nurtów twórczych i&nbsp;stylów w&nbsp; sztuce,
            które pojawiły się jako reakcja na osiemnastowieczną, przebrzmiałą stylistykę i&nbsp;
            estetykę otoczenia. Inspirowana folklorem sztuka ojczysta, symbolizm narodowy,
            futurystyczna awangarda, art déco, konstruktywizm, formizm i&nbsp;pomniejsze prądy
            artystyczne tworzyły klimat wizualnej i&nbsp;mentalnej różnorodności.
          </p>
          <p>
            Na ulicach królowały plakaty i&nbsp;afisze, głównie reklamowe rozwijających się
            przedsiębiorczych firm. Plakaty traktowano jako rodzaj twórczości artystycznej,
            w&nbsp;której uwidoczniała się preferowana konwencja estetyczna i&nbsp;bardzo często
            osobisty styl autorów. Wśród nazwisk twórców plakatu międzywojennego znajdują się
            nazwiska znakomitych malarzy i&nbsp;grafików: Edmunda Bartłomiejczyka, Władysława
            Skoczylasa, Tadeusza Gronowskiego, Anatola Girsa i&nbsp;Bolesława Barcza, Tadeusza
            Trepkowskiego, Jana Lewitta, Jerzego Hima i&nbsp; innych.
          </p>
          <p>
            Rysowane i&nbsp;malowane przez grafików oryginały plakatów trafiały najczęściej do
            drukarń offsetowych. Fotografowano i&nbsp;rastrowano je w&nbsp;aparatach reprodukcyjnych
            typu Klimsch i&nbsp;w postaci klisz filmowych poszczególnych separacji barwnych służyły
            do produkcji offsetowych form drukowych. Tworząc artystyczne oryginały łączono różne
            techniki malarskie. Często sporządzano szablony liternicze i&nbsp;figuralne,
            a&nbsp;potem nakładano farby przez rozpylanie, prószenie lub tapowanie. Używano farb
            laserunkowych lub kryjących, albo obu rodzajów równocześnie. Popularne były gwasze
            z&nbsp;białymi wypełniaczami zwane plakatówkami. Te gęste farby wodne znacznie lepiej
            pokrywały papier niż akwarele. Często operowano jednolitą aplą. Napisy plakatów nie
            powstawały ze składu zecerskiego, ale tworzyły jedność z&nbsp;całą formą graficzną.
            Przetworzone artystycznie, ręczne lub szablonowe, nierzadko naśladowały kroje czcionek
            drukarskich.
          </p>
          <p>
            Obok plakatów powstawały drukowane techniką typograficzną afisze, także pełniące istotne
            funkcje informacyjne i&nbsp;reklamowe. Rozlepiane na słupach ogłoszeniowych, płotach
            i&nbsp; wierzejach bram, to właśnie one decydowały o&nbsp;wyglądzie ówczesnych miejskich
            ulic. Podobnie jak w&nbsp;anonsach w&nbsp;wysokonakładowej prasie, składano je
            w&nbsp;drukarniach różnymi rodzajami pism. Oprócz odlewanych czcionek metalowych zecerzy
            dysponowali tzw. drewniakami, czyli sporządzanymi z&nbsp;drewna czcionkami bardzo dużych
            rozmiarów, którymi składano hasła i&nbsp;tytuły. Afisze zwykle drukowano jednobarwnie.
            Niekiedy jednak stosowano prostą a&nbsp;efektowną metodę „irys”. Polegała ona na
            równoczesnym drukowaniu dwoma-trzema kolorami farb umieszczonych w&nbsp;kałamarzu
            typograficznej maszyny drukującej. Podczas pracy wałków nanoszących farbę sąsiadujące ze
            sobą kolory mieszały się tworząc gamę przenikających się barw.
          </p>
        </div>
      </TextAndImageContainer>

      <TextAndImageContainer>
        <h3>Polska typografia kopiowana</h3>
      </TextAndImageContainer>

      <TextAndImageContainer>
        <TextFigure caption="Il. 2" $visibleOnDesktop fluid={images['opracowania-2']} />

        <div>
          <p>
            W&nbsp;XIX i&nbsp;na początku XX wieku wytwórcy czcionek kopiowali kroje pism swoich
            konkurentów, czasem zmieniali drobne szczegóły graficzne, a&nbsp;we wzornikach swoich
            firm nadawali krojom pism nowe nazwy. Proceder ten uprawiano nagminnie w&nbsp;Niemczech,
            ale podobnie postępowano w&nbsp;całej Europie, m.in. w&nbsp;Polsce. Zgodnie
            z&nbsp;dzisiejszymi prawami autorskimi i&nbsp; własnościowymi tego rodzaju działania
            określamy jako naganne, a&nbsp;i wówczas były niezgodne z&nbsp;nieformalnym kodem
            moralnym. Dążenie do maksymalizacji zysku przedsiębiorstwa zawsze jednak było
            i&nbsp;będzie w&nbsp;konflikcie z&nbsp;poczuciem przyzwoitości.
          </p>
          <p>
            Kluczem do plagiatowania liternictwa drukarskiego była nowa technologia galwanicznego
            sporządzania matryc odlewniczych. W&nbsp;giserniach czcionek wykorzystano zjawisko
            elektrolizy, czyli osadzanie się na jednej z&nbsp;elektrod warstwy metalu (np. miedzi
            lub niklu) pochodzącego z&nbsp;elektrolitycznego roztworu. Kupowano gotowe czcionki
            innej odlewni, odcinano słupki, a&nbsp;jej liternicze oczka umieszczano w&nbsp;kąpieli
            galwanicznej. Po pewnym czasie na wypukłych oczkach czcionek powstawała tzw. koszulka
            galwaniczna tworząca wgłębną replikę oczka. Następnie odpowiednio przygotowana stawała
            się matrycą do produkcji czcionek skopiowanego kroju pisma.
          </p>
          <TextFigure caption="Il. 2" $visibleOnMobile fluid={images['opracowania-2']} />
          <p>
            W&nbsp;ten właśnie sposób rozszerzał się repertuar krojów pism warszawskich producentów
            czcionek: Samuela Orgelbranda w&nbsp;drugiej połowie XIX wieku i&nbsp;w pierwszej
            dekadzie XX wieku odlewni Stanisława Jeżyńskiego oraz Jana Idźkowskiego. Pierwszorzędnym
            źródłem dobrze opracowanych graficznie i&nbsp;wzorowo technicznie wykonanych pism była
            renomowana berlińska Hermann Berthold Schriftgiesserei AG. Odlewnia Bertholda, znana
            z&nbsp;jakości i&nbsp; różnorodności produktów, przodowała na naszym kontynencie.
            Fabryka miała oddziały w&nbsp; kilku miastach europejskich, m.in. w&nbsp;Wiedniu,
            Petersburgu i&nbsp;Moskwie. Z&nbsp;firmą Berthold w&nbsp; różnych latach współpracowali
            znani graﬁcy, projektanci pism drukarskich: Herbert Post, Georg Trump, Imre Reiner,
            Louis Oppenheim, Martin Wilke i&nbsp;inni. Dzisiaj znaczna liczba bertholdowskich krojów
            pism jest używana w&nbsp;postaci fontów komputerowych. Pamiętajmy jednak, że stanowią
            one tylko ułamek wieloletniego dorobku tego Domu Typograﬁcznego.
          </p>
        </div>
      </TextAndImageContainer>

      <TextAndImageContainer>
        <TextFigure caption="Il. 3" fluid={images['opracowania-3']} />

        <div>
          <p>
            Odlewnie czcionek w&nbsp;Warszawie należącej do Królestwa Kongresowego chętnie sięgały
            do wzorów H. Berthold AG z&nbsp;jeszcze jednego powodu. Otóż drukarnie na polskich
            terenach rosyjskiego zaboru musiały dysponować pismami dwóch alfabetów: łacińskiego
            i&nbsp; cyrylickiego (rosyjskiej grażdanki). Czcionki z&nbsp;petersburskiej fabryki
            Bertholda w&nbsp; większości krojów pism były produkowane w&nbsp;obu alfabetach. To była
            konieczność w&nbsp;firmie zaopatrującej zecernie rozległego imperium, a&nbsp;warszawskie
            odlewnie wybierały właśnie takie kroje, które pozwalały na zachowanie typograficznego
            ładu w&nbsp;dwujęzycznych, polsko-rosyjskich drukach.
          </p>
        </div>
      </TextAndImageContainer>

      <TextAndImageContainer>
        <h3>Secesyjny krój Renesans</h3>
      </TextAndImageContainer>

      <TextAndImageContainer>
        <TextFigure caption="Il. 4" fluid={images['opracowania-4']} />

        <div>
          <p>
            Gisernia Jan Idźkowski i&nbsp;S-ka, założona w&nbsp;Warszawie przez Jana Nepomucena
            Idźkowskiego i&nbsp; Józefa Drozdowskiego, zainaugurowała działalność w&nbsp;1909 roku,
            a&nbsp;więc pięć lat przed rozpoczęciem I&nbsp;wojny światowej. Początkowo, nie mając
            własnych graficznych projektów pism, kopiowano niemieckie kroje. Szybko zwrócono uwagę
            na rozbudowany krój Secession firmy Berthold. Wiarygodny Handbuch der Schriftarten
            wydany przez Seemann Verlag w&nbsp; Lipsku w&nbsp;1926 roku oraz Index of Typefaces
            (ATypI, 1975) podają, że podstawowa odmiana kroju Secession (Sezession) powstała
            w&nbsp;atelier berlińskiej giserni w&nbsp;1901 roku, półgruba (halbfette) w&nbsp;1903,
            rok później odmiana konturowa, natomiast gruba (fette) w&nbsp;1907. Wybrana przez firmę
            Idźkowski i&nbsp;S-ka linearna antykwa bezszeryfowa była wówczas pismem nowatorskim,
            charakterystycznym dla modnej moderny. Już sama nazwa oryginalnego kroju wyrażała
            kontestację stylu XIX-wiecznego realizmu i&nbsp;odejście w&nbsp;stronę nowoczesności.
          </p>
          <p>
            Utartym zwyczajem plagiatorów czcionek u&nbsp;Idźkowskiego zmieniono imię własne kroju
            z&nbsp; Secession na Renesans i&nbsp;pod tą nazwą krój był używany w&nbsp;Polsce aż do
            drugiej połowy XX wieku. W&nbsp;dwudziestoleciu międzywojennym chętnie stosowano go
            w&nbsp;składaniu tytulariów oraz w&nbsp;anonsach i&nbsp;tekstach reklamowych.
          </p>
          <p>
            Matryce odlewnicze trzech kompletów kroju Renesans przechowuje w&nbsp;Łodzi Muzeum
            Książki Artystycznej prowadzone przez Jadwigę i&nbsp;Janusza Pawła Tryznów.
          </p>
        </div>
      </TextAndImageContainer>

      <TextAndImageContainer>
        <TextFigure caption="Il. 5" $visibleOnDesktop fluid={images['opracowania-5']} />

        <div>
          <h3>Amerykańska historia kroju Kolumbia </h3>

          <TextFigure caption="Il. 5" $visibleOnMobile fluid={images['opracowania-5']} />

          <p>
            Historia drugiego bohatera naszej strony internetowej, często używanego w&nbsp;
            dwudziestoleciu międzywojennym kroju Kolumbia (Columbia), jest również bogata. Tropy
            proweniencyjne prowadzą do tygodnika „Saturday Evening Post” wychodzącego
            w&nbsp;Filadelfii. Grafik czasopisma E. J. Kitson zaprojektował charakterystyczny krój
            o&nbsp;pofalowanych krawędziach liter podkreślających zgrzebność odręcznego rysunku.
            Pismo w&nbsp;duchu modnego Arts & Crafts, błyskawicznie zdobywające popularność,
            produkowane było w&nbsp;kilku giserniach w&nbsp;USA i&nbsp;w Europie. Szybkość
            rozprzestrzeniania się czcionek kroju Kitsona była imponująca. Początkowo używane
            w&nbsp;winiecie „Saturday Evening Post”, a&nbsp;potem w&nbsp; tytulariach tygodnika,
            odlano w&nbsp;American Type Founders Co. w&nbsp;postaci czcionek dwóch wariantach: Post
            Oldstyle Roman No. 1&nbsp;oraz No. 2. Następnie krój trafił do Bostonu, gdzie
            funkcjonowała Hansen Type Foundry założona przez Norwega H. C. Hansena, wcześniej
            pracownika giserni Dickinsona w&nbsp;tym samym mieście. W&nbsp;1902 roku lub nieco
            wcześniej u&nbsp; Hansena wprowadzono do produkcji czcionki o&nbsp;nazwie Buffalo.
            Rodzina kroju miała też afiszową odmianę Buffalo Poster. Nawiasem mówiąc, na początku
            lat 70. Buffalo trafił na fotomatryce nowojorskiej Photo-Lettering Inc.
          </p>
          <p>
            Krój pisma odlewano w&nbsp;pierwszej dekadzie XX wieku pod różnymi nazwami, np.
            w&nbsp;giserni Renault jako Cleveland, w&nbsp;Società Augusta jako Franklin czy też
            u&nbsp;Stevensa jako Nelson Old Style. W&nbsp;1904 roku holenderska gisernia
            Lettergieterij Amsterdam (dawniej Nicolaas Tetterode) wykonała własną wersję Buffalo
            i&nbsp;oferowała pod nazwą Columbia.
          </p>
          <p>
            Mniej więcej w&nbsp;tym samym czasie powstała niemiecka adaptacja kroju pod nazwą
            Kolonial. Czcionki produkowała Wilhelm Woellmer’s Schriftgiesserei w&nbsp;Berlinie.
            Właśnie ta wersja kroju była odlewana w&nbsp;Warszawie: pod oryginalną nazwą Kolonial
            u&nbsp;Stanisława Jeżyńskiego i&nbsp;jako Columbia (Kolumbia) u&nbsp;Jana Idźkowskiego.
            Jeszcze w&nbsp;katalogu z&nbsp;1954 roku Odlewnia Czcionek P.P. (upaństwowiona firma J.
            Idźkowski i&nbsp;S-ka) oferowała drukarniom czcionki z&nbsp; Kolumbią.
          </p>
          <p>
            Duża część matryc z&nbsp;obu odlewni zachowała się w&nbsp;zbiorach łódzkiego Muzeum
            Książki Artystycznej: Columbia w&nbsp;siedemnastu typowymiarach (stopniach pisma danej
            odmiany kroju) oraz jeden komplet kroju Kolonial z&nbsp;giserni Jeżyńskiego.
          </p>
        </div>
      </TextAndImageContainer>
    </StyledTextWrapper>
  );
};

Tomaszewski.propTypes = {};

export default Tomaszewski;

import React from 'react';
import styled, { css } from 'styled-components';

import { HeroLink } from 'components/HeroSection';
import { paragraphFontSizeCss } from 'components/GlobalStyle';

import { texts } from './constants';

export const StyledWrapper = styled.div``;

export const StyledLinks = styled.div`
  margin-top: 36px;
`;

export const StyledLinkToText = styled(HeroLink)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 8px;
  ${paragraphFontSizeCss};
`;

export const StyledTitle = styled.span`
  font-style: italic;
`;

export const StyledAuthor = styled.span`
  display: block;

  ${({ theme }) => css`
    ${theme.mediaQueries.tablet.min} {
      display: inline;
    }
  `}
`;

const OpracowaniaHeroSectionContent = () => (
  <StyledWrapper>
    <p>
      W&nbsp;dziale opracowania znajdziecie teksty podejmujące temat typografii, reklamy
      i&nbsp;plakatów reklamowych w&nbsp;międzywojennej Polsce.
    </p>
    <p>
      Anna Maria Lewandowska-Ferenc scharakteryzuje różne rodzaje międzywojennej reklamy
      reprodukowanej, opisze cechy skutecznej reklamy oraz to, w&nbsp;jaki sposób można było zlecić
      jej wykonanie.
    </p>
    <p>
      Andrzej Tomaszewski opisze technologię przygotowywania plakatów oraz afiszy, „kopiowania”
      czcionek pochodzących z&nbsp;zagranicznych odlewni, jak również odkryje historię krojów
      Renesans i&nbsp; Kolumbia.
    </p>
    <p>
      Michał Warda przedstawi rozwój plakatu reklamowego, pierwsze organizacje zawodowe zrzeszające
      twórców reklamy, a&nbsp;także przybliży twórczość Tadeusza Gronowskiego i&nbsp;Stefana
      Norblina.
    </p>

    <StyledLinks>
      {Object.entries(texts).map(([key, { id, title, author }]) => (
        <StyledLinkToText href={`#${id}`} key={key}>
          <StyledTitle>{title},</StyledTitle> <StyledAuthor>{author}</StyledAuthor>
        </StyledLinkToText>
      ))}
    </StyledLinks>
  </StyledWrapper>
);

OpracowaniaHeroSectionContent.propTypes = {};

export default OpracowaniaHeroSectionContent;

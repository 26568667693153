import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from 'components/Container';

export const StyledWrapper = styled.div``;

const AnnotationItemsContainer = ({ children }) => <Container mt={16}>{children}</Container>;

AnnotationItemsContainer.propTypes = {
  children: PropTypes.node,
};

export default AnnotationItemsContainer;

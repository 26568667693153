import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

export const StyledWrapper = styled.figure`
  display: block;
  margin: 20px 0 24px;
  padding: 0;

  ${({ theme }) => css`
    ${theme.mediaQueries.smallDesktop.min} {
      margin-top: 0;
      order: 1;
      margin-bottom: auto;
    }
  `}

  ${({ $visibleOnDesktop, theme }) =>
    $visibleOnDesktop &&
    css`
      display: none;

      ${theme.mediaQueries.smallDesktop.min} {
        display: block;
      }
    `}

  ${({ $visibleOnMobile, theme }) =>
    $visibleOnMobile &&
    css`
      ${theme.mediaQueries.smallDesktop.min} {
        display: none;
      }
    `}
`;

export const StyledFigcaption = styled.figcaption`
  margin-top: 8px;
  font-style: italic;
`;

const TextFigure = ({ $visibleOnDesktop, $visibleOnMobile, caption, fluid }) => (
  <StyledWrapper $visibleOnDesktop={$visibleOnDesktop} $visibleOnMobile={$visibleOnMobile}>
    <GatsbyImage fluid={fluid} />
    {caption && <StyledFigcaption>{caption}</StyledFigcaption>}
  </StyledWrapper>
);

TextFigure.propTypes = {
  caption: PropTypes.string,
  $visibleOnDesktop: PropTypes.bool,
  $visibleOnMobile: PropTypes.bool,
  fluid: PropTypes.object,
};

export default TextFigure;

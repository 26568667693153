import React from 'react';

export const annotations = [
  {
    plain: 'O. Langer, Zasady ogłaszania, Warszawa 1927, s. 211.',
    element: (
      <React.Fragment>
        O. Langer, <i>Zasady ogłaszania</i>, Warszawa 1927, s. 211.
      </React.Fragment>
    ),
  },
  {
    plain: 'K. Homolacs, Reklama i sztuka, „Rzeczy Piękne” 1927, nr 6, s. 82.',
    element: (
      <React.Fragment>
        K. Homolacs, <i>Reklama i&nbsp;sztuka</i>, „<i>Rzeczy Piękne</i>” 1927, nr 6, s. 82.
      </React.Fragment>
    ),
  },
  {
    plain: '„Reklama”. Organ Polskiego Związku Reklamowego, Kwartalnik, 1929, nr 1.',
    element: (
      <React.Fragment>
        „Reklama”. Organ Polskiego Związku Reklamowego, Kwartalnik, 1929, nr 1.
      </React.Fragment>
    ),
  },
];
